import PropTypes from 'prop-types';

function ConditionItem({ ruleArray, level }) {
  if (ruleArray.length === 1) {
    return <ConditionItem ruleArray={ruleArray[0]} level={level} />;
  }

  if (ruleArray.length && ruleArray.every((i) => typeof i === 'string')) {
    return (
      <div key={level} id="condition_drawer_if_block_item">
        <div>If</div>
        <div id="condition_drawer_if_block_item_variable">{ruleArray[0]}</div>
        <div id="condition_drawer_if_block_item_condition">{ruleArray[1]}</div>
        <div id="condition_drawer_if_block_item_value">{ruleArray[2]}</div>
      </div>
    );
  }
  return (
    <div id="condition_drawer_if_block" className={`${level !== 0 && 'border-box'}`}>
      {ruleArray.length
        && ruleArray.map((rule) => {
          if (typeof rule !== 'string') {
            return (
              <ConditionItem key={level} ruleArray={rule} level={level + 1} />
            );
          }
          return (
            <div key={level} id="condition_drawer_if_block_between">
              {rule}
            </div>
          );
        })}
    </div>
  );
}

ConditionItem.propTypes = {
  level: PropTypes.number.isRequired,
  ruleArray: PropTypes.array.isRequired,
};

export default ConditionItem;
