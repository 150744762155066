import { useSelector } from 'react-redux';

const useGetModulesStyle = () => {
  const modules = useSelector((state) => state.workflow.modules);
  const styles = [
    { color: '#4a0ae7', border: 'solid 1px rgba(74, 10, 231, 0.1)' },
    { color: '#de4175', border: 'solid 1px rgba(222, 73, 112, 0.1)' },
    { color: '#a00064', border: 'solid 1px rgba(160, 0, 100, 0.1)' },
    { color: '#ff6e00', border: 'solid 1px rgba(255, 110, 0, 0.1)' },
    { color: '#0453fb', border: 'solid 1px rgba(4, 83, 251, 0.1)' },
    { color: '#a05000', border: 'solid 1px rgba(160, 80, 0, 0.1)' },
    { color: '#02b56b', border: 'solid 1px rgba(2, 181, 107, 0.1)' },
    { color: '#dc3cb4', border: 'solid 1px rgba(85, 78, 241, 0.1)' },
    { color: '#649a0b', border: 'solid 1px rgba(100, 154, 11, 0.1)' },
    { color: '#e64632', border: 'solid 1px rgba(230, 70, 50, 0.1)' },
    { color: '#006964', border: 'solid 1px rgba(0, 105, 100, 0.1)' },
    { color: '#00b4c8', border: 'solid 1px rgba(0, 180, 200, 0.1)' },
  ];

  const styleMap = {};
  Object.keys(modules).forEach((module, index) => {
    if (!(modules[module].uiConfig
      && modules[module].uiConfig.landingPage
      && modules[module].uiConfig.landingPage.hide)) {
      styleMap[module] = styles[index % styles.length];
    }
  });

  return styleMap;
};

export default useGetModulesStyle;
