import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import AlertCode from '../constants/alertCodes';
import useShowErrorAlert from './customHooks';
import { updateModules, updateGlobalConfig } from '../reducers/workflow';
import { DRAWER_TYPES, endStates, condition } from '../components/constants';
import { updateDocToken } from '../reducers/onboard';

const useApiHooks = () => {
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const dispatch = useDispatch();
  const showErrorAlert = useShowErrorAlert();

  const addGenericModules = (modules) => {
    const temp = modules;

    Object.entries(temp).forEach(([key]) => {
      temp[key].nodeDisplayType = 'default';
      temp[key].DRAWER_TYPE = DRAWER_TYPES[key] || DRAWER_TYPES.api_drawer;
    });

    temp.condition = { ...condition };
    temp.condition.nodeDisplayType = 'condition';
    temp.condition.DRAWER_TYPE = DRAWER_TYPES.condition;

    temp.start.nodeDisplayType = 'input';
    temp.start.DRAWER_TYPE = DRAWER_TYPES.none;
    Object.entries(endStates).forEach(([key, value]) => {
      temp[key] = { ...value };
      temp[key].nodeDisplayType = 'output';
      temp[key].DRAWER_TYPE = DRAWER_TYPES.output;
    });
    return temp;
  };

  const fetchModules = useCallback(async () => {
    try {
      const dataRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/modules`,
        headers: { appId: currentAppId },
      });
      const modules = addGenericModules(dataRes.data.result.modules);
      dispatch(updateModules({ modules }));
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchModules });
    }
  });

  const fetchGlobalConfig = async () => {
    try {
      const dataRes = await axios({
        method: 'GET',
        url: 'https://hv-central-config.s3.ap-south-1.amazonaws.com/gsdk-country-doc-list/countries.json',
        withCredentials: false,
      });
      const globalConfig = dataRes.data;
      const countryDocMappingTemp = {};
      const countryIdNameMapping = {};
      (globalConfig).forEach((config) => {
        const docs = config.documents.map((d) => d.id);
        const docsNames = {};
        config.documents.forEach((d) => {
          docsNames[d.id] = d.name;
        });
        countryDocMappingTemp[config.id] = { documentIds: docs, documentNames: docsNames };
        countryIdNameMapping[config.id] = config.name;
      });
      dispatch(updateGlobalConfig({
        globalConfig,
        countryDocMapping: countryDocMappingTemp,
        countryIdNameMapping,
      }));
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchCountries });
    }
  };

  const fetchDocToken = async () => {
    try {
      const userRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/onboard/docToken`,
      });
      dispatch(updateDocToken(userRes.data.result));
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.docToken });
    }
  };

  return { fetchModules, fetchGlobalConfig, fetchDocToken };
};

export default useApiHooks;
