import { useEffect, useState, useMemo } from 'react';
import { PermissionWrapper } from 'storybook-ui-components';
import {
  useNodesState,
  useEdgesState,
} from 'react-flow-renderer';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import useShowErrorAlert, { useShowCustomAlert } from '../utils/customHooks';
import './Container.scss';
import convertToNodesEdges from '../components/utils';
import { DRAWER_TYPES } from '../components/constants';
import GenericModuleDrawer from '../components/ViewWorkflow/GenericModuleDrawer';
import AlertCode from '../constants/alertCodes';
import CountrySelection from '../components/ViewWorkflow/CountrySelection';
import DocumentDrawer from '../components/ViewWorkflow/DocumentDrawer';
import DefaultDrawer from '../components/ViewWorkflow/DefaultDrawer';
import CustomNode from '../components/CustomNode';
import edgeTwoArrows from '../assests/icons/edgeTwoArrows.svg';
import ConditionDrawer from '../components/ViewWorkflow/ConditionDrawer';
import OutputDrawer from '../components/ViewWorkflow/OutputDrawer';
import ConditionEdge from '../components/ConditionEdge.js';
import ModuleEdge from '../components/ModuleEdge';
import ELKLayout from '../components/ViewWorkflow/ELKLayout';
import useGetUserPermissions from '../Permissions/hooks';
import getPermission from '../Permissions/mapping';

const ViewWorkflow = () => {
  const { search } = useLocation();
  const [workflowConfig, setWorkflowConfig] = useState({});
  const [workflowName, setWorkflowName] = useState('');
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [drawerType, setDrawerType] = useState(DRAWER_TYPES.none);
  const [selectedNode, setSelecteNode] = useState('');

  const countryDocMapping = useSelector((state) => state.workflow.countryDocMapping);
  const workflowModules = useSelector((state) => state.workflow.modules);
  const navigate = useNavigate();

  const currentAppId = useSelector((state) => state.user.currentAppId);
  const showErrorAlert = useShowErrorAlert();
  const showCustomAlert = useShowCustomAlert();

  const workflowId = new URLSearchParams(search).get('id');

  const addUIPropertiesNodes = (workflowNodes) => {
    const uiAddedNodes = workflowNodes.map((node) => {
      try {
        const properties = workflowModules[node.nodeType];
        const { nodeDisplayType, uiConfig } = properties;
        if (nodeDisplayType === 'condition') {
          return {
            ...node,
            type: nodeDisplayType,
            className: 'conditions_module',
            data: {
              label: (
                <img src={edgeTwoArrows} alt="edge_two_arrows" className="custom_edge_button__img" />
              ),
            },
          };
        }
        return {
          ...node,
          type: nodeDisplayType,
          className: 'nodes__module',
          data: {
            label: (
              <CustomNode
                heading={uiConfig.node.heading}
                subHeading={uiConfig.node.subHeading}
              />
            ),
          },
        };
      } catch (error) {
        throw new Error(`Error loading config: module ${node.nodeType} does not exist`);
      }
    });
    return uiAddedNodes;
  };

  const fetchWorkflow = async (id) => {
    try {
      const dataRes = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/id`,
        headers: { appId: currentAppId },
        data: { workflowId: id },
      });
      const workflow = dataRes.data.result;
      setWorkflowConfig(workflow);
      setWorkflowName(workflow.properties && workflow.properties.name ? workflow.properties.name : workflow.name || 'Workflow');
      const converted = convertToNodesEdges(dataRes.data.result);
      const uiAddedNodes = addUIPropertiesNodes(converted.nodes);
      setNodes(uiAddedNodes);
      setEdges(converted.edges);
    } catch (error) {
      console.log(error.message);
      showErrorAlert({ error, message: AlertCode.error.fetchWorkflow });
    }
  };

  const onNodeClick = (event, node) => {
    const { DRAWER_TYPE } = workflowModules[node.nodeType];
    edges.forEach((edge) => {
      const edgeElement = document.getElementById(`${edge.id}_button_edge`);
      if (edge.source === node.id && edgeElement) {
        edgeElement.classList.add('active');
      } else if (edgeElement) {
        edgeElement.classList.remove('active');
      }
    });
    // Might need this later
    // edges.forEach((edge) => {
    //   const edgePath = document.getElementById(edge.id);
    //   if (edge.source === node.id && edgePath) {
    //     edgePath.classList.add('button_edge_path_selected');
    //   } else if (edgePath) {
    //     edgePath.classList.remove('button_edge_path_selected');
    //   }
    // });
    setSelecteNode(node);
    setDrawerType(DRAWER_TYPE);
  };

  const updateWorkflow = async () => {
    try {
      await axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/id`,
        headers: { appId: currentAppId },
        data: { workflowId, workflowConfig },
      });
      showCustomAlert({
        message: workflowId,
        alertSeverity: 'success',
      });
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.ERROR_UPLOADING_WORKFLOW });
    }
  };

  // TODO When we enable changing properties
  // eslint-disable-next-line no-unused-vars
  const onConditionSubmit = async ({
    sourceNode, selectedOption, selectedComparison, selectedValue,
  }) => {
    const updatedWorkflow = workflowConfig;
    const { modules } = workflowConfig;
    const found = modules.find((element) => element.id === sourceNode.id);
    const matchedCondition = found.nextStep;
    updatedWorkflow.conditions[matchedCondition].rule = `(${sourceNode.id}.${selectedOption} ${selectedComparison} ${selectedValue})`;
    setWorkflowConfig(updatedWorkflow);
  };

  // TODO When we enable changing properties
  // eslint-disable-next-line no-unused-vars
  const onPropertiesSubmit = (({
    sourceNode, parameters, requiredInputs, documentsSupported,
  }) => {
    let properties = { ...parameters };
    if (documentsSupported) properties = { ...properties, documentsSupported };

    let update = { properties };
    if (requiredInputs
      && Object.keys(requiredInputs).length > 0) update = { ...update, requiredInputs };

    const updatedWorkflow = workflowConfig;
    const { modules } = workflowConfig;
    const idx = modules.findIndex((element) => element.id === sourceNode.id);
    const updatedModule = {
      ...modules[idx], ...update,
    };
    updatedWorkflow.modules[idx] = updatedModule;

    setWorkflowConfig(updatedWorkflow);
  });

  // TODO When we enable changing properties
  // eslint-disable-next-line no-unused-vars
  const onCountriesSubmit = (({
    sourceNode, countriesSupported,
  }) => {
    const properties = { countriesSupported };

    // Add countries
    const updatedWorkflow = workflowConfig;
    const { modules } = workflowConfig;
    const idx = modules.findIndex((element) => element.id === sourceNode.id);
    const updatedModule = {
      ...modules[idx], properties,
    };

    updatedWorkflow.modules[idx] = updatedModule;

    const docIdx = [];
    modules.forEach((module, i) => {
      if (module.subType === 'id_card_verification') docIdx.push(i);
    });
    const { documentsSupported } = modules[docIdx[0]].properties;
    const updatedDocumentsSupported = {};
    // Remove docs of countries not present
    const removedCountries = (Object.keys(documentsSupported)
      .filter((x) => !countriesSupported.includes(x)));
    Object.entries(documentsSupported).forEach(([key, value]) => {
      if (!removedCountries.includes(key)) updatedDocumentsSupported[key] = value;
    });

    // Add docs of countries newly added
    const addedCountries = (countriesSupported.filter(
      (x) => !Object.keys(updatedDocumentsSupported).includes(x),
    ));
    addedCountries.forEach((country) => {
      updatedDocumentsSupported[country] = countryDocMapping[country];
    });

    docIdx.forEach((doc) => {
      const update = modules[doc];
      update.properties.documentsSupported = updatedDocumentsSupported;
      updatedWorkflow.modules[doc] = update;
    });

    setWorkflowConfig(updatedWorkflow);
  });

  const edgeTypes = useMemo(() => (
    {
      conditionEdge: (data) => (ConditionEdge(data)),
      moduleEdge: (data) => (ModuleEdge(data)),
    }), []);

  const changeCSS = () => {
    const style = document.createElement('style');
    style.innerHTML = '.react-flow__handle.connectable { visibility: hidden;}';
    document.getElementsByTagName('head')[0].appendChild(style);
  };

  useEffect(() => {
    fetchWorkflow(workflowId);
    changeCSS();
  }, [workflowId]);

  let drawerContent = (
    <DefaultDrawer workflowConfig={workflowConfig} workflowId={workflowId} />
  );
  if (drawerType === DRAWER_TYPES.api_drawer) {
    drawerContent = (
      <GenericModuleDrawer
        selectedNode={selectedNode}
        workflowConfig={workflowConfig}
      />
    );
  } else if (drawerType === DRAWER_TYPES.countries) {
    drawerContent = (
      <CountrySelection
        workflowConfig={workflowConfig}
        selectedNode={selectedNode}
      />
    );
  } else if (drawerType === DRAWER_TYPES.id_card_verification) {
    drawerContent = (
      <DocumentDrawer
        workflowConfig={workflowConfig}
        selectedNode={selectedNode}
      />
    );
  } else if (drawerType === DRAWER_TYPES.condition) {
    drawerContent = (
      <ConditionDrawer
        selectedNode={selectedNode}
        workflowConfig={workflowConfig}
      />
    );
  } else if (drawerType === DRAWER_TYPES.output) {
    drawerContent = (
      <OutputDrawer
        selectedNode={selectedNode}
        workflowConfig={workflowConfig}
      />
    );
  }

  const goBack = () => {
    navigate('/');
  };

  return (
    <div className="view_workflow__container">
      <Grid container>
        <Grid item xs={4} className="view_workflow__heading_container">
          <ArrowBackIcon onClick={goBack} sx={{ color: 'rgba(5, 5, 82, 0.5)' }} />
          <h2 className="view_workflow__heading">
            {workflowName}
          </h2>
        </Grid>
        <Grid item xs={8}>
          <Button variant="outlined" id="view_workflow__publish_button" onClick={updateWorkflow} disabled>Publish</Button>
        </Grid>
        <div className="view_workflow__body_container">
          <div className="view_workflow__workflow_container">
            {nodes.length && edges.length && (
              <ELKLayout
                initialNodes={nodes}
                initialEdges={edges}
                edgeTypes={edgeTypes}
                onNodeClick={onNodeClick}
              />
            )}
          </div>
          {drawerContent}
        </div>
      </Grid>
      {/* <CustomAlert /> */}
    </div>
  );
};

export default PermissionWrapper(ViewWorkflow, useGetUserPermissions, getPermission('workflowDetailsPage'));
