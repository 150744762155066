import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CustomTooltip from '../Common/CustomTooltip';
import AlertCode from '../../constants/alertCodes';
import { useShowCustomAlert } from '../../utils/customHooks';
import BaseDrawer from './BaseDrawer';
import { formatDateWithDateMonth } from '../../utils/dateFormats';
import DrawerTable from './DrawerTable';

function DefaultDrawer({ workflowConfig, workflowId }) {
  // eslint-disable-next-line no-unused-vars
  const [sdkVersions, setSdkVersions] = useState({});
  const [sdkInputs, setSdkInputs] = useState({});
  const [workflowName, setWorkflowName] = useState('');
  const [workflowCreatedAt, setWorkflowCreatedAt] = useState();
  const [workflowUpdatedAt, setWorkflowUpdatedAt] = useState();
  const [workflowIdCopied, setWorkflowIdCopied] = useState(false);
  const showCustomAlert = useShowCustomAlert();

  useEffect(() => {
    const {
      // eslint-disable-next-line camelcase
      properties, name, createdAt, updatedAt,
    } = workflowConfig;
    // eslint-disable-next-line camelcase
    if (properties) {
      if (properties.sdk_versions) setSdkVersions(properties.sdk_versions);
      if (properties.inputsRequired) setSdkInputs(properties.inputsRequired);
    }
    setWorkflowName(name);
    setWorkflowCreatedAt(createdAt);
    setWorkflowUpdatedAt(updatedAt);
  }, [workflowConfig]);

  return (
    <BaseDrawer heading="Workflow Details" subHeading="An overview to get you started">
      <Grid container className="default_drawer_summary_container">
        <Grid item xs={12}>
          <div className="drawer_content__heading">Summary</div>
        </Grid>
        <Grid item xs={12}>
          <div className="default_drawer_detail_sub_container">
            <div id="default_drawer_workflow_id__text_container">
              <span className="default_drawer_content__left_text">Workflow ID: </span>
              <span className="default_drawer_content__right_text" id="default_drawer_workflow_id__text">
                {workflowId}
                &ensp;
                {workflowIdCopied ? <span className="tooltiptext">copied</span> : (
                  <CopyToClipboard
                    text={workflowId}
                    onCopy={() => {
                      const payload = {
                        message: AlertCode.COPIED_TO_CLIPBOARD,
                      };
                      showCustomAlert(payload);
                      setWorkflowIdCopied(true);
                    }}
                  >
                    <span className="tooltiptext">copy</span>
                  </CopyToClipboard>
                )}
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="default_drawer_detail_sub_container">
            <div>
              <span className="default_drawer_content__left_text">Name: </span>
              <span className="default_drawer_content__right_text">{workflowName}</span>
            </div>
          </div>
        </Grid>
      </Grid>

      {(workflowCreatedAt || workflowUpdatedAt) ? (
        <Grid container>
          <Grid item xs={12}>
            <div className="drawer_content__heading">Timeline</div>
          </Grid>
          {workflowCreatedAt
            ? (
              <Grid item xs={12}>
                <div className="default_drawer_detail_sub_container">
                  <div>
                    <span className="default_drawer_content__left_text">Created At: </span>
                    <span className="default_drawer_content__right_text">{formatDateWithDateMonth(workflowCreatedAt)}</span>
                  </div>
                </div>
              </Grid>
            ) : '' }
          {workflowUpdatedAt
            ? (
              <Grid item xs={12}>
                <div>
                  <div className="default_drawer_detail_sub_container">
                    <span className="default_drawer_content__left_text">Date Last Modified: </span>
                    <span className="default_drawer_content__right_text">{formatDateWithDateMonth(workflowUpdatedAt)}</span>
                  </div>

                </div>
              </Grid>
            ) : ''}
        </Grid>
      ) : '' }
      {/* Hide SDK Versions for now
      <Grid item xs={12}>
        <div className="drawer_content__heading">SDK Compatibility</div>
      </Grid>
      <br />
      <br />
      <Grid container>
        {Object.keys(sdkVersions).map((platform) => (
          <Grid key={platform} item xs={4}>
            <div className="drawer_content__heading">{platform}</div>
            <span className="default_drawer_content__left_text">MAX : </span>
            <span className="default_drawer_content__right_text">
            {sdkVersions[platform].maximum}</span>
            <div>
              <span className="default_drawer_content__left_text">MIN : </span>
              <span className="default_drawer_content__right_text">
                {' '}
                {sdkVersions[platform].minimum}
              </span>
            </div>
          </Grid>
        ))}
      </Grid> */}
      <Grid item xs={12}>
        {Object.keys(sdkInputs).length > 0 ? (
          <div className="default_drawer_content_sub_container">
            <div className="drawer_content__heading">
              Inputs to SDK
              <CustomTooltip
                title={(
                  <>
                    These are fields that have to be passed to the SDK at the time of integration.
                    {'  '}
                    <a href="https://developer.hyperverge.co/global-beta/kyc/sdk-inputs" className="drawer_tooltip__link">Learn More</a>
                  </>
                )}
                placement="top"
                arrow
              >
                <div className="drawer_info__icon">
                  i
                </div>
              </CustomTooltip>
            </div>
            <DrawerTable tableElements={sdkInputs} headings={['Field Name', 'Field Type']} />
          </div>
        ) : <></>}
      </Grid>
    </BaseDrawer>
  );
}

DefaultDrawer.propTypes = {
  workflowConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  workflowId: PropTypes.string.isRequired,
};

export default DefaultDrawer;
