/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    appIds: {},
    currentAppId: '',
    permissions: null,
    role: '',
    appIdKeyToken: '',
    email: '',
    currentAppIdType: '',
    useCases: {},
    currentUseCase: '',
    useCaseAppIds: [],
  },
  reducers: {
    updateUserInfo: (state, action) => {
      const {
        permissions, role, email, useCases, appIds,
      } = action.payload;
      state.permissions = permissions;
      state.role = role;
      state.email = email;
      state.useCases = useCases;
      state.appIds = appIds;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserInfo,
} = userSlice.actions;
export default userSlice.reducer;
