/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import BaseDrawer from './BaseDrawer';
import CountriesList from './CountriesList';
import ModuleInput from './ModuleInput';
import ModuleOutput from './ModuleOutput';

function MultiSelectValues({
  workflowConfig, selectedNode,
}) {
  const [showExpandedCountries, setShowExpandedCountries] = useState(false);
  const [uiConfig, setUIConfig] = useState({
    drawer: {},
  });
  const [countries, setCountries] = useState([]);
  const workflowModules = useSelector((state) => state.workflow.modules);
  const [nodeConfig, setNodeConfig] = useState({});

  useEffect(() => {
    const found = workflowConfig.modules.find((element) => element.id === selectedNode.id);
    setNodeConfig(found);
    setCountries(found.properties.countriesSupported);
    setUIConfig(workflowModules[selectedNode.nodeType].uiConfig);
  }, [selectedNode]);

  const expandCountries = () => {
    setShowExpandedCountries(true);
  };

  const goBack = () => {
    setShowExpandedCountries(false);
  };

  if (showExpandedCountries) {
    return (
      <BaseDrawer
        heading="Countries Selected"
        subHeading={` Users from ${countries.length} countries listed below will be allowed to apply`}
        iconRenderFunction={(() => <ArrowBackIcon className="drawer_back__icon" onClick={goBack} />)}
      >
        <CountriesList countries={countries} type="large" />
      </BaseDrawer>
    );
  }

  return (
    <BaseDrawer heading={uiConfig.drawer.heading} subHeading={uiConfig.drawer.subHeading}>
      <ModuleInput nodeConfig={nodeConfig} workflowConfig={workflowConfig} />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <div className="drawer_content__heading">Countries Selected</div>
          </Grid>
          <Grid item xs={12}>
            <div className="drawer_content__subheading">
              Users from
              {' '}
              {countries.length}
              {' '}
              countries listed below will be allowed to apply
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CountriesList countries={countries.slice(0, 6)} />
        {countries.length >= 6 ? (
          <div className="drawer_country__list_expand_text" onClick={expandCountries}>
            +
            {' '}
            {countries.length - 6}
            {' '}
            countries
          </div>
        ) : <></>}
      </Grid>
      <ModuleOutput selectedNode={selectedNode} />
    </BaseDrawer>
  );
}

MultiSelectValues.propTypes = {
  workflowConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedNode: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MultiSelectValues;
