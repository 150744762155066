import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function CountriesList({ countries, type }) {
  const countryIdNameMapping = useSelector((state) => state.workflow.countryIdNameMapping);

  return (
    <Box className="drawer_country__list_container">
      <List className="drawer_country__list_ul_container">
        {countries.map((countryId) => (
          <ListItem disablePadding key={countryId}>
            <ListItemButton className={`drawer_country__list_button_${type}`}>
              <ListItemIcon className={`drawer_country__list_icon_${type}`}>
                <img src={`https://hv-central-config.s3.ap-south-1.amazonaws.com/gsdk-country-doc-list/flags/${countryId}.png`} alt={countryId} />
              </ListItemIcon>
              <div className={`drawer_country__list_text_${type}`}>
                {countryIdNameMapping[countryId]}
              </div>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

CountriesList.defaultProps = {
  type: 'small',
};

CountriesList.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  type: PropTypes.string,
};
