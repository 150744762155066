/* eslint-disable react/jsx-no-undef */
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import './Container.scss';
import CustomWorkflowAlert from '../components/Common/CustomWorkflowAlert';
import useApiHooks from '../utils/useApiHooks';

axios.defaults.withCredentials = true;

function Main() {
  const { fetchModules, fetchGlobalConfig, fetchDocToken } = useApiHooks();

  useEffect(() => {
    const startApp = async () => {
      fetchDocToken();
      fetchModules();
      fetchGlobalConfig();
    };
    startApp();
  }, []);

  return (
    <>
      <Outlet />
      <CustomWorkflowAlert />
    </>
  );
}

export default Main;
