/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { endStates } from './constants';

const conditionMap = {
  '!=': 'is not',
  '==': 'is',
};

const betweenMap = {
  '||': 'or',
  '&&': 'and',
};

const betweenRegex = /(&&|\|\|)/;
const conditonRegex = /(!=|==)/;
const paranthsisRegex = /\((.*?)\)/;

const arrayToJson = (arr) => {
  const obj = {};
  arr.forEach((element) => {
    obj[element.id] = element;
  });
  return obj;
};

// parses a string and returns paranthesis blocks
const paranthesisParser = (str) => {
  const stack = [];
  let startIndex = 0;
  const paranthesisMap = {};
  for (let i = 0; i < str.length; i += 1) {
    if (str[i] === '(') {
      if (stack.length === 0) {
        startIndex = i;
      }
      stack.push(str[i]);
    } else if (str[i] === ')') {
      stack.pop();
      if (stack.length === 0) {
        const param = {
          old: str.slice(startIndex + 1, i),
          new: str.slice(startIndex, i + 1),
        };
        const key = `${startIndex}_${i + 1}`;
        paranthesisMap[key] = param;
      }
    }
  }
  return paranthesisMap;
};

const recursiveFetchRules = (arr, modules) => {
  const newArr = [];
  let paranthesisMap;
  arr.forEach((item) => {
    // check if paranthesis exists
    // replace paranthesis with a simpler key
    if (paranthsisRegex.test(item)) {
      paranthesisMap = paranthesisParser(item, modules);
      Object.keys(paranthesisMap).forEach((key) => {
        item = item.replace(paranthesisMap[key].new, key);
      });
    }
    // check if string is && or ||
    if (item in betweenMap) {
      newArr.push(betweenMap[item]);
    } else if (item in conditionMap) { // check if string is != or ==
      newArr.push(conditionMap[item]);
    } else if (betweenRegex.test(item)) { // check if || or && is part of string
      // split the string on if || or &&
      const paramSplit = item.split(betweenRegex).filter(Boolean).map((it) => it.trim());
      // check if split strings are part of simplified paranthesis map
      // if so, substitute them with child recursiveFetchModules
      // else append child recursiveFetchModules
      if (paranthesisMap) {
        for (let i = 0; i < paramSplit.length; i += 1) {
          if (paramSplit[i] in paranthesisMap) {
            // eslint-disable-next-line prefer-destructuring
            paramSplit[i] = recursiveFetchRules([paranthesisMap[paramSplit[i]].old], modules);
          } else {
            // eslint-disable-next-line prefer-destructuring
            paramSplit[i] = recursiveFetchRules([paramSplit[i]], modules)[0];
          }
        }
        newArr.push(...paramSplit);
      } else {
        newArr.push(...recursiveFetchRules(paramSplit, modules));
      }
    } else if (conditonRegex.test(item)) { // check if != or == is part of string
      // split the string on if != or ==
      const paramSplit = item.split(conditonRegex).filter(Boolean);
      // check if split strings are part of simplified paranthesis map
      // if so, substitute them with child recursiveFetchModules
      // else append child recursiveFetchModules
      if (paranthesisMap) {
        for (let i = 0; i < paramSplit.length; i += 1) {
          if (paramSplit[i] in paranthesisMap) {
            // eslint-disable-next-line prefer-destructuring
            paramSplit[i] = recursiveFetchRules([paranthesisMap[paramSplit[i]].old], modules);
          } else {
            // eslint-disable-next-line prefer-destructuring
            paramSplit[i] = recursiveFetchRules([paramSplit[i]], modules)[0];
          }
        }
        newArr.push(paramSplit);
      } else {
        newArr.push(recursiveFetchRules(paramSplit, modules));
      }
    } else if (paranthesisMap && item in paranthesisMap) {
      newArr.push(recursiveFetchRules([paranthesisMap[item].old], modules));
    } else {
      // check if key has a module Ex: module1.statusCode
      const [moduleId, key] = item.split('.');
      if (!moduleId || !key) {
        newArr.push(_.startCase(item));
      } else {
        modules.forEach((mod) => {
          if (mod.id === moduleId) {
            newArr.push(`${_.startCase(key)} From ${_.startCase(mod.subType)}`);
          }
        });
      }
    }
  });
  return newArr;
};

// BFS Algorithm to recursively convert the config into nodes and edges
export default function convertToNodesEdges(config) {
  const { modules, conditions } = config;
  const modulesJson = arrayToJson(modules);
  const nodes = [];
  const edges = [];

  const queue = [{
    ...modules[0], x: 0, level: 0,
  }];
  const completedNodeIds = [];

  while (queue.length > 0) {
    const module = queue.shift();
    const {
      id, nextStep, type,
    } = module;

    // eslint-disable-next-line no-continue
    if (completedNodeIds.includes(id)) continue;
    completedNodeIds.push(id);
    // check if module is condition
    if (type === 'condition') {
      const { if_true, if_false, rule } = module;
      const ruleArr = recursiveFetchRules([rule], modules);
      let passNodeId; let failNodeId;
      const conditionData = {
        ruleArr,
      };
      nodes.push({
        id,
        nodeType: module.subType,
        ...conditionData,
      });
      if (conditions[if_true]) {
        passNodeId = if_true;
        queue.push({
          ...conditions[if_true], id: passNodeId, type: 'condition', subType: 'condition',
        });
      } else if (modulesJson[if_true]) {
        passNodeId = modulesJson[if_true].id;
        queue.push({
          ...modulesJson[if_true], id: passNodeId,
        });
      } else {
        passNodeId = `${if_true}_${id}`;
        nodes.push({
          id: passNodeId,
          nodeType: if_true,
        });
      }
      if (conditions[if_false]) {
        failNodeId = if_false;
        queue.push({
          ...conditions[if_false], id: failNodeId, type: 'condition', subType: 'condition',
        });
      } else if (modulesJson[if_false]) {
        failNodeId = modulesJson[if_false].id;
        queue.push({
          ...modulesJson[if_false], id: failNodeId,
        });
      } else {
        failNodeId = `${if_false}_${id}`;
        nodes.push({
          nodeType: if_false,
          id: failNodeId,
        });
      }
      edges.push({
        id: `${id}_${passNodeId}`, source: id, target: passNodeId, type: 'conditionEdge', animated: false, data: { branch: 'Current Branch' },
      }, {
        id: `${id}_${failNodeId}`, source: id, target: failNodeId, type: 'conditionEdge', animated: false, data: { branch: 'New Branch' },
      });
    } else if (type !== 'condition') {
      nodes.push({
        nodeType: module.subType, id,
      });
      let targetNodeId;
      if (modulesJson[nextStep]) {
        targetNodeId = modulesJson[nextStep].id;
        queue.push({
          ...modulesJson[nextStep], id: targetNodeId,
        });
      } else if (conditions[nextStep]) {
        targetNodeId = nextStep;
        queue.push({
          ...conditions[nextStep], id: targetNodeId, type: 'condition', subType: 'condition',
        });
      } else {
        targetNodeId = `${nextStep}_${id}`;
        nodes.push({
          id: targetNodeId,
          nodeType: nextStep,
        });
      }
      edges.push({
        id: `${id}_${targetNodeId}`, source: id, target: targetNodeId, type: 'moduleEdge', animated: false,
      });
    }
  }

  // Adding all common edge properties
  edges.forEach((edge) => {
    if (!edge.type) edge.type = 'step';
    edge.style = {};
    edge.style.strokeDasharray = '7.5';
    edge.style.stroke = '#ACA9F8';
  });

  return { nodes, edges };
}

// Convert nodes, edges to config
const convertToConfig = ({ nodes, edges }) => {
  const nodesJson = arrayToJson(nodes);

  const modules = [];
  const conditions = {};

  nodes.forEach((node) => {
    const { id, position, nodeType } = node;

    const module = {
      subType: nodeType, id, nextStep: '',
    };

    const targets = [];

    // Get targets for the source
    edges.forEach((edge) => {
      if (edge.source === id) targets.push(edge.target);
    });

    // Two targets - Condition
    if (targets.length === 2) {
      const node1 = nodesJson[targets[0]];
      const node2 = nodesJson[targets[1]];
      let if_true = node1.id;
      let if_false = node2.id;

      // If node1 is not in same x-coordinate, then it is the if_false node
      if (node1.position.x !== position.x) {
        if_true = node2.id;
        if_false = node1.id;
      }
      const conditionKey = `condition_${Object.keys(conditions).length}`;
      conditions[conditionKey] = {
        if_true,
        if_false,
      };
      module.nextStep = conditionKey;
    } else {
      [module.nextStep] = targets;
    }

    // Only add modules
    if (!Object.keys(endStates).includes(nodeType)) modules.push(module);
  });

  // Clean module names
  modules.forEach((module) => {
    // eslint-disable-next-line no-param-reassign
    if (typeof module.nextStep === 'string') {
      const temp = module.nextStep.split('_')[0];
      if (Object.keys(endStates).includes(temp)) module.nextStep = temp;
    }
  });

  // Clean edge names
  Object.entries(conditions).forEach(([, value]) => {
    const { if_true, if_false } = value;
    let temp = if_true.split('_')[0];
    if (Object.keys(endStates).includes(temp)) {
      value.if_true = temp;
    }
    [temp] = if_false.split('_');
    if (Object.keys(endStates).includes(temp)) value.if_false = temp;
  });

  return { modules, conditions };
};

export {
  convertToConfig,
};
