import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BaseDrawer from './BaseDrawer';

function OutputDrawer({ selectedNode }) {
  const [moduleUIConfig, setUIConfig] = useState({
    drawer: {},
  });
  const workflowModules = useSelector((state) => state.workflow.modules);

  const resetState = () => {
    setUIConfig({
      drawer: {},
    });
  };

  useEffect(() => {
    resetState();
    const { uiConfig } = workflowModules[selectedNode.nodeType];
    if (uiConfig) {
      setUIConfig(workflowModules[selectedNode.nodeType].uiConfig);
    }
  }, [selectedNode]);

  return (
    <BaseDrawer
      heading={moduleUIConfig.drawer.heading}
      subHeading={moduleUIConfig.drawer.subHeading}
    >
      <p style={{ fontSize: '12px', color: 'rgba(5, 5, 82, 0.6)' }}>{moduleUIConfig.drawer.subHeading}</p>
    </BaseDrawer>
  );
}

OutputDrawer.propTypes = {
  selectedNode: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OutputDrawer;
