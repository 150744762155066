import { Route, Routes, Outlet } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import Main from './containers/Main';
import PrivateRoute from './utils/PrivateRoute';
import Builder from './containers/Builder';
import ViewWorkflow from './containers/ViewWorkflow';
import WorkflowList from './components/WorkflowList';
import NotFound from './containers/NotFound';
import DrawerLayout from './containers/DrawerLayout';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <div className="App">
        <Routes>
          <Route path="/" element={<PrivateRoute><Main /></PrivateRoute>}>
            <Route path="/" element={<DrawerLayout />}>
              <Route path="/" element={<WorkflowList />} />
            </Route>
            <Route path="/" element={<><Outlet /></>}>
              <Route path="view" element={<ViewWorkflow />} />
              <Route path="build" element={<Builder />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </StyledEngineProvider>
  );
}

export default App;
