import {
  get,
} from 'lodash';
import workflowDetailsPagePermissions from './pages/workflowDetailsPage';
import workflowListPagePermissions from './pages/workflowListPage';

const requiredPermissionsMapping = {
  ...workflowListPagePermissions,
  ...workflowDetailsPagePermissions,
};

const getPermission = (id) => get(
  requiredPermissionsMapping, id, {},
);

export default getPermission;
