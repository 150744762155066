import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import '../Workflow.scss';

export default function DocumentList({ documents }) {
  const countryIdNameMapping = useSelector((state) => state.workflow.countryIdNameMapping);
  const countryDocMapping = useSelector((state) => state.workflow.countryDocMapping);

  return (
    <Paper elevation={0}>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                key="country"
                className="drawer_table__heading"
              >
                Country
              </TableCell>
              <TableCell
                key="document_supported"
                className="drawer_table__heading"
              >
                Documents Supported
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(documents).map(([key, value]) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                <TableCell key={`${key}_country`} className="drawer_content__text">
                  {countryIdNameMapping[key]}
                </TableCell>
                <TableCell key={`${key}_documents`} className="drawer_content__text">
                  {(() => {
                    const temp = [];
                    value.forEach((docId) => {
                      temp.push(countryDocMapping[key].documentNames[docId]);
                    });
                    return temp.join(', ');
                  })()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

DocumentList.propTypes = {
  documents: PropTypes.objectOf(PropTypes.any).isRequired,
};
