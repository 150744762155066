import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import AlertCode from '../constants/alertCodes';
import { showAlert, closeAlert } from '../reducers/alert';

const useShowErrorAlert = () => {
  const dispatch = useDispatch();

  const showErrorAlert = ({ error, message }) => {
    let alertMessage = message;
    if (error) {
      if (error.response) {
        if (error.response.status === 401) {
          alertMessage = AlertCode.error.login;
        }
      } else { Sentry.captureException(error); }
    }

    dispatch(showAlert({ alertSeverity: 'error', message: alertMessage }));
    setTimeout(() => {
      dispatch(closeAlert());
    }, 2000);
  };
  return showErrorAlert;
};

const useShowCustomAlert = () => {
  const dispatch = useDispatch();

  const showCustomAlert = ({ alertSeverity, message }) => {
    dispatch(showAlert({ alertSeverity, message }));
    setTimeout(() => {
      dispatch(closeAlert());
    }, 2000);
  };
  return showCustomAlert;
};

const useCloseAlertAction = () => {
  const dispatch = useDispatch();

  const closeAlertAction = () => {
    dispatch(closeAlert());
  };
  return closeAlertAction;
};

export default useShowErrorAlert;
export { useShowCustomAlert, useCloseAlertAction };
