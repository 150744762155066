import PropTypes from 'prop-types';
import '../Workflow.scss';

function BaseDrawer({
  heading, subHeading, children, iconRenderFunction, uiProps,
}) {
  return (
    <div className={`view_workflow__drawer_container ${uiProps.isFlexible ? 'flexible' : ''}`}>
      <div className="drawer_top__container">
        <div className="drawer_top__container_icon">
          {iconRenderFunction()}
        </div>
        <div className="drawer_top__container_heading">
          <div className="drawer_top__heading">{heading}</div>
          <div className="drawer_top__subheading">{subHeading}</div>
        </div>
      </div>
      <div className="drawer_content__container">
        {children}
      </div>
    </div>
  );
}

BaseDrawer.defaultProps = {
  iconRenderFunction: (() => <div className="drawer_top__icon" />),
  heading: '',
  subHeading: '',
  uiProps: {},
};

BaseDrawer.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  children: PropTypes.node.isRequired,
  iconRenderFunction: PropTypes.func,
  uiProps: PropTypes.object,
};

export default BaseDrawer;
