const AlertCode = {
  error: {
    login: 'Please Login Again',
    fetchUser: 'Error in fetching user',
    fetchWorkflows: 'Error in fetching workflows',
    default: 'Something went wrong',
    fetchModules: 'Error in fetching modules',
    fetchCountries: 'Error in fetching countries',
    docToken: 'Error in fetching doc token',
    fetchWorkflow: 'Error fetching workflow',
  },
  COPIED_TO_CLIPBOARD: 'Copied To Clipboard',
};
export default AlertCode;
