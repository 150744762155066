/* eslint-disable react/jsx-props-no-spreading */

import TableNameCell from './TableNameCell';
import TableModules from './TableModules';

export const tableHeaders = {
  name: {
    label: 'Workflow',
    width: '25%',
  },
  description: {
    label: 'Details',
    width: '30%',
  },
  modules: {
    label: 'Modules Included',
    width: '45%',
  },
};

export const genTableData = (workflows) => Object.entries(workflows).map(
  ([id, { name, description, moduleNames }]) => ({
    name: {
      data: { id },
      componentRenderer: (props) => (
        <>
          <TableNameCell name={name} id={id} {...props} />
        </>
      ),
    },
    description: {
      componentRenderer: () => (
        <>
          {description}

        </>
      ),
    },
    modules: {
      componentRenderer: (props) => (
        <TableModules
          modules={moduleNames}
          {...props}
        />
      ),
    },
  }),
);
