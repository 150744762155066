import { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactFlow, { useNodesState, useEdgesState, Controls } from 'react-flow-renderer';
import ELK from 'elkjs';
import './layout.scss';

const nodeWidth = 215;
const conditionWidth = 29;
const nodeHeight = 50;

const getLayoutedElements = async (nodes, edges) => {
  const elk = new ELK({});
  const newNodes = nodes.map((node) => ({ ...node, width: nodeWidth, height: nodeHeight }));
  const newEdges = edges.map((node) => (
    { ...node, sources: [node.source], targets: [node.target] }
  ));
  const graph = {
    id: 'root',
    layoutOptions: {
      'elk.algorithm': 'layered',
      'elk.spacing.nodeNode': '100',
      'elk.direction': 'DOWN',
      'elk.layered.spacing.nodeNodeBetweenLayers': '70',
      'elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
    },
    children: newNodes,
    edges: newEdges,
  };
  const layout = await elk.layout(graph);
  const layoutNodes = layout.children.map((node) => {
    let nodeX = node.x;
    // Centering conditions
    if (node.nodeType === 'condition') {
      nodeX += (nodeWidth / 2 - conditionWidth / 2);
    }
    return { ...node, position: { x: nodeX, y: node.y } };
  });
  return { nodes: layoutNodes, edges: layout.edges };
};

const ELKLayout = ({
  initialNodes, initialEdges, edgeTypes, onNodeClick,
}) => {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  useEffect(() => {
    async function asyncFunc() {
      const { nodes: layoutedNodes, edges: layoutedEdges } = await getLayoutedElements(
        initialNodes,
        initialEdges,
      );
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    }
    asyncFunc();
  }, [initialNodes, initialEdges]);

  return (
    <div className="layout">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        edgeTypes={edgeTypes}
        onNodeClick={onNodeClick}
        fitView
      >
        <Controls showInteractive={false} />
      </ReactFlow>
    </div>
  );
};

ELKLayout.propTypes = {
  initialNodes: PropTypes.array.isRequired,
  initialEdges: PropTypes.array.isRequired,
  edgeTypes: PropTypes.object.isRequired,
  onNodeClick: PropTypes.func.isRequired,
};

export default ELKLayout;
