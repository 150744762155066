import PropTypes from 'prop-types';
import './Workflow.scss';

function CustomNode({ heading, subHeading }) {
  return (
    <div className="custom_node">
      <div className="custom_node__icon" />
      <div className="custom_node__body">
        <p className="custom_node__heading">
          {heading}
        </p>
        <p className="custom_node__subheading">
          {subHeading}
        </p>
      </div>
    </div>
  );
}

CustomNode.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
};

export default CustomNode;
