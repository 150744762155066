import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AlertCode from '../../constants/alertCodes';
import { useShowCustomAlert } from '../../utils/customHooks';

function TableNameCell({ id, name, isHoveredOn }) {
  const showCustomAlert = useShowCustomAlert();
  return (
    <div id="workflow_table__body--workflow_name_container">
      <p id="workflow_table__body--workflow_name">
        {name}
      </p>
      <div id="workflow_table__body--workflow_id_container">
        <p id="workflow_table__body--workflow_id">
          workflow id:
          {' '}
          {id}
        </p>
        <CopyToClipboard
          text={id}
          onCopy={() => {
            showCustomAlert({
              message: AlertCode.COPIED_TO_CLIPBOARD,
            });
          }}
        >
          <p
            id="workflow_table__body--workflow_copy"
            aria-hidden
            onClick={(e) => e.stopPropagation()}
            className={isHoveredOn && 'visible'}
          >
            Copy
          </p>
        </CopyToClipboard>
      </div>
    </div>
  );
}

TableNameCell.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.object.isRequired,
  isHoveredOn: PropTypes.bool.isRequired,
};

export default TableNameCell;
