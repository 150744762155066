/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import BaseDrawer from './BaseDrawer';
import DocumentList from './DocumentList';
import ModuleInput from './ModuleInput';
import ModuleOutput from './ModuleOutput';

function DocProperties({
  workflowConfig, selectedNode,
}) {
  const [documents, setDocuments] = useState({});
  const [uiConfig, setUIConfig] = useState({
    drawer: {},
  });
  const [showExpandedDocuments, setShowExpandedDocuments] = useState(false);
  const workflowModules = useSelector((state) => state.workflow.modules);
  const [nodeConfig, setNodeConfig] = useState({});

  useEffect(() => {
    const found = workflowConfig.modules.find((element) => element.id === selectedNode.id);
    setNodeConfig(found);
    setDocuments(found.properties.documentsSupported);
    setUIConfig(workflowModules[selectedNode.nodeType].uiConfig);
  }, [selectedNode]);

  const expandDocuments = () => {
    setShowExpandedDocuments(true);
  };

  const goBack = () => {
    setShowExpandedDocuments(false);
  };

  if (showExpandedDocuments) {
    return (
      <BaseDrawer
        heading="List of Documents Supported"
        subHeading="For the countries selected, the documents supported are listed below: "
        iconRenderFunction={(() => <ArrowBackIcon className="drawer_back__icon" onClick={goBack} />)}
      >
        <DocumentList documents={documents} />
      </BaseDrawer>
    );
  }

  return (
    <BaseDrawer heading={uiConfig.drawer.heading} subHeading={uiConfig.drawer.subHeading}>
      <ModuleInput nodeConfig={nodeConfig} workflowConfig={workflowConfig} />
      <Grid item xs={12}>
        <DocumentList documents={(Object.fromEntries(Object.entries(documents).slice(0, 6)))} />
      </Grid>
      {Object.keys(documents).length >= 6 ? (
        <div className="drawer_doc__list_expand_text" onClick={expandDocuments}>
          +
          {' '}
          {Object.keys(documents).length - 6}
          {' '}
          countries&apos;s documents
        </div>
      ) : <></>}
      <ModuleOutput selectedNode={selectedNode} />
    </BaseDrawer>
  );
}

DocProperties.propTypes = {
  workflowConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedNode: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DocProperties;
