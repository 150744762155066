import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BaseDrawer from './BaseDrawer';
import ConditionItem from './ConditionItem';

function ConditionDrawer({ selectedNode }) {
  const [ruleArray, setRuleArray] = useState([]);
  const [moduleUIConfig, setUIConfig] = useState({
    drawer: {},
  });

  const workflowModules = useSelector((state) => state.workflow.modules);

  const resetState = () => {
    setRuleArray([]);
    setUIConfig({
      drawer: {},
    });
  };

  useEffect(() => {
    resetState();
    const { ruleArr } = selectedNode;
    const { uiConfig } = workflowModules[selectedNode.nodeType];
    if (uiConfig) {
      setUIConfig(uiConfig);
    }
    setRuleArray(Object.assign(ruleArr));
  }, [selectedNode]);

  return (
    <BaseDrawer
      heading={moduleUIConfig.drawer.heading}
      subHeading={moduleUIConfig.drawer.subHeading}
      uiProps={{ isFlexible: true }}
    >
      <div id="condition_drawer_container">
        <p id="condition_drawer_container_heading">What gets checked?</p>
        <div style={{ paddingLeft: '20px' }}>
          <ConditionItem ruleArray={ruleArray} level={0} />
        </div>
        <p>If the above checks are true, </p>
        <div id="condition_drawer_then_block">
          <p>
            then execute
            <span>
              Current Branch
            </span>
          </p>
        </div>
        <p>If the above checks are false, </p>
        <div id="condition_drawer_else_block">
          <p>
            then execute
            <span>
              New Branch
            </span>
          </p>
        </div>
      </div>
    </BaseDrawer>
  );
}

ConditionDrawer.propTypes = {
  selectedNode: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ConditionDrawer;
