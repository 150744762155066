/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState: {
    globalConfig: [],
    countryDocMapping: {},
    countryIdNameMapping: {},
    modules: {},
  },
  reducers: {
    updateGlobalConfig: (state, action) => {
      const { globalConfig, countryDocMapping, countryIdNameMapping } = action.payload;
      state.globalConfig = globalConfig;
      state.countryDocMapping = countryDocMapping;
      state.countryIdNameMapping = countryIdNameMapping;
    },
    updateModules: (state, action) => {
      const { modules } = action.payload;
      state.modules = modules;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateGlobalConfig, updateModules,
} = workflowSlice.actions;
export default workflowSlice.reducer;
