/* eslint-disable react/prop-types */
import { getSmoothStepPath, getEdgeCenter } from 'react-flow-renderer';
import './Workflow.scss';

const foreignObjectWidth = 120;
const foreignObjectHeight = 40;
const foreignObjectDisplaceFromPath = 1.1;
const foreignObjectCenterOnPath = 2;

export default function ButtonEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) {
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });
  const isOnSameX = Math.round(sourceX) === Math.round(targetX);
  const isOnSameY = Math.round(sourceY) === Math.round(targetY);
  let foreignObjectY = edgeCenterY - foreignObjectHeight;
  if (isOnSameY) {
    foreignObjectY = edgeCenterY - foreignObjectHeight * foreignObjectDisplaceFromPath;
  } else if (isOnSameX) {
    foreignObjectY = edgeCenterY - foreignObjectHeight / foreignObjectCenterOnPath;
  }
  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectWidth}
        height={foreignObjectHeight}
        x={isOnSameX ? edgeCenterX - foreignObjectWidth * foreignObjectDisplaceFromPath
          : edgeCenterX - foreignObjectWidth / foreignObjectCenterOnPath}
        y={foreignObjectY}
      >
        <div id={`${id}_button_edge`} className="button_edge">
          {data.branch}
        </div>
      </foreignObject>
    </>
  );
}
